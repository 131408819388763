@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Quicksand:wght@300;400&family=Roboto&display=swap');

.body {
    background-color: rgb(66, 64, 64);
    padding: 0px;
    margin-top: 100px;padding-top: 20px;
    width: 100% !important;
}

.h1 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    color: rgb(16, 61, 79); 
    font-size: 3.2rem;
    text-transform: uppercase;
}

.h3 {
    color: rgb(16, 61, 79);
    font-size: 18px; 
    text-transform: uppercase;
}
.h5 {
    color: rgb(16, 61, 79);
    font-size: 1.6rem; 
    text-transform: uppercase;
}

.h6{
    font-size: 1.3rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 6px;
    justify-content: end;
}

.h5, .h6 {
    text-align: right;
}

.greentext{
    /* color:#0b7c0b; */
    color: rgb(16, 61, 79); 
}

.parent {
    display: flex;
}

p{
    margin-bottom: 0;
}

.sibilingOne {
    width: 40%;
}

.sibilingTwo {
    width: 55%;
    padding-left: 10px;
}

.container {
    margin: 1em 2em 2em 2em;
    background-color: white;
    width: 1200px;
    height: 60cm;
    padding: 30px;
    /* height: 1500px; */
}

.container1 {
    margin: 1em 2em 2em 2em;
    background-color: white;
    width: 1200px;
    height:45cm;
    padding: 30px;
    /* height: 22cm; */
}

.header {
    justify-content: end;
}

.paragraph {
    font-family: 'ROBOTO', 'Josefin Sans', sans-serif;
    /* font-weight: 300; */
    text-align: left;
    /* font-size: 18px;  */
    font-size: 16px; 
    line-height: 16px;  
     margin-bottom: 35px;
    padding-left: 15px;
}

/* .separatorParagraph {

} */


.cardTitle {
    text-align: left;
    font-size: 1.45em;
    /* color:#0b7c0b; */
    padding-left: 40px;
    color: rgb(16, 61, 79); 
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    margin-top: 2rem;
}

.cardPosition01 {
    margin-top: -40px;
}


.cardTitleTechnicalInformation{
        text-align: center;
        font-size: 1.5em;
        /* color: #0b7c0b; */
        color: rgb(16, 61, 79); 
        text-transform: uppercase;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: bolder;
        margin-top: 2rem;    
}

.cardImage {
    margin-top: 30px;
    width: 100%;
    height: 500px;
    position: relative;
    margin-left: -90px;
    margin-bottom: 90px;
}
.imageMain {
    width: 130%;
    /* object-fit: cover; */
    /* fit: 300 300; */
    max-width: 160%;
    /* height: 100%; */
    padding: 30px 10px 0 90px;
}


.imageColorHeader {
    width: 100px;
}

.imageColor {
    width: 67px;
}
.hr {
    margin-top: 1px;
    border: 0;
    /* border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor; */  
    border-top: 1px solid rgba(0, 0, 0, 0.54);
}

.separatorOne {
    margin:0;
    padding: 0;
}

.containerColor{
    width: 100%;
}

.cardColor{
    width: 22%;
}

.cardContainer{
    width: 68%;
    margin-left: 100px;
    padding-left: 15px;
    justify-content: right;
    align-items: end;
    text-align: end;
}

.textFooterColor {
    margin-top: 15px;
    color: gray;
    font-size: 12px;
    text-align: left;
    margin-left: 15px;
}

.textFooter1 {
    text-align: center;
}

.footerFixed1 {
    position: absolute;
    margin-bottom: 0px; 
    width: 1050px;
    top: 1685px;
    left: 100px;
    text-align: center;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: gray;
  }

  .footerFixed2 {
    position: absolute;   
    padding-bottom: 0;
    margin-bottom: 0px; 
    top: 4135px;
    left: 120px;
    width: 1050px;
    text-align: center;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: gray;
  }
  .cardImageSecondPage {
    margin-top: -10px;
    width: 120%;
    justify-content: center;
  }

  .imageSecondPage{
    justify-content: center;
    width: 60%;
    margin-left: 147px;
  }
  
  .paragraphSecondPage {
    font-family: 'ROBOTO', sans-serif;
    /* font-weight: 300; */
    text-align: left;
    font-size: 18px; 
    line-height: 4px;  
     margin-bottom: 35px;
    padding-left: 15px;
  }

  .gray_row {
    background-color: #f2f2f2; /* Cinza */
  }
  
  .white_row {
    background-color: #ffffff; /* Branco */
  }
  
  .content_table{
    width: 100%;
    justify-content: center;
    /* background-color: yellow; */
  }

.table {
    margin-left: 250px;
    justify-content: center;
    align-items: center;
    text-align: left;
}

table {
    width: 715px;
  height: 489px;
}

li{
    line-height: 32px;
}

p{
    line-height: 16px;
}

.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.99);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000; 
  } 
   
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo preto semi-transparente */
    z-index: 9999; /* Certifique-se de que está acima de todos os outros elementos */
  }
  
  .tableHeader {
    background-color: gray;
    color: white;
  }

  .mafirolCom{
    padding-top: 19px;
    padding-right: 35px;
  }