@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.event , .event-wrapper {
  min-height: 450px;  
  margin-left: 10vw;
  margin-right: 5vw;
  justify-content: left;
  align-items: left;
  text-align: left;  
}

.event-wrapper .row{
  margin-top: 0vw;
  margin-left: 0vw;
  flex-wrap: wrap;
  justify-content: left;
  align-items: left;
  text-align: left;
  /* width: 70vw; */
}

.event .row{
  margin-left: 7vw;
}

.teste001{
  margin-top: 0vw;
}

.dados{
  text-align: center;
}




