.custom_player_wrapper .video-react-controls {
  display: none;
}

.card_body01 {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
  border-top: solid black 1px;
  margin-top: 18px;
}

.card_title01 {
  text-align: left;
  align-items: start;
  font-size: 19px;
  font-weight: bold;
  margin-top: 8px;
  margin-left: 0 !important;
  padding-left: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-family: 'Roboto';
  font-weight: 400;
}

.card_text01 {
  text-align: left;
  align-items: start;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  margin-left: 0 !important;
  padding-left: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  flex: 1;
}

