@media screen and (max-width: 1300px) {
    .grid-container01 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .card01 { 
    width: 18.1rem;
    height: 23rem;
    max-width: none;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  }
  
  .card-image01 {
    width: 100%;
    height: 12rem;
    object-fit: cover;
  }
  
  .card-body01 {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    border-top: solid black 1px;
    margin-top: 18px;
  }
  
  .card-title01 {
    text-align: left;
    align-items: start;
    font-size: 19px;
    font-weight: bold;
    margin-top: 8px;
    margin-left: 0 !important;
    padding-left: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-family: 'Roboto';
    font-weight: 400;
  }
  
  .card-text01 {
    text-align: left;
    align-items: start;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    margin-left: 0 !important;
    padding-left: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    flex: 1;
  }
  
  .grid-container01 {
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(3, 1fr) !important;
    grid-template-columns: repeat(auto-fit, minmax(18.1rem, 1fr)); */
    gap: 50px;
    justify-content: center;
  }
  