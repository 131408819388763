/* --------------------------------- REACT CAROUSEL ORGININAL ----------------------------------------------------------- */

@media (min-width: 992px){
.container-lg, .container-md, .container-sm, .container {
  max-width: 1041px;
  }
}

@media (min-width: 1200px){
.container-xl, .container-lg, .container-md, .container-sm, .container {
  max-width: 1100px;
 }
}
.image-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-carousel .image-media {
  margin: 0 auto;
    margin-bottom: 0px;
  max-height: 500px;
  width: auto;
  max-width: 100%;
}

.container-media{
  justify-content: center;
  text-align: center;
  align-items:center ;
}

/* --------------------------------- REACT CAROUSEL ORGININAL ----------------------------------------------------------- */

.pagination{
    overflow-y: scroll;
}