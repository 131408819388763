.content {
    text-align: center;
    margin-bottom: 30px;
  }
  .header {
    font-size: 25px;
  }
  
  .img-body img {
    display: flex;
    margin: 0 auto;
    height: 40vh;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .img-body {
    width: 150px;
    text-align: center;
    aspect-ratio: 1/1;
    outline-color: red;
    display: inline-block;
    /* opacity: .9; */
  }

  .img-cut img {
    width: 100%;
  }

  .shadow {
    color: #164176;
    font-size: 3.1rem !important;
    text-align: center;
    font-weight: 400;
    position: relative;    
    letter-spacing: -2px !important;
}
   
.dataSheet {
  min-height: 50px;
  align-items: center;
}
.dataSheet .dataSheetItem {
  min-height: 50px;
  align-items: center;
}

.selected {
  background-color: #5a92ec;
  color: #f5f5f5;
  padding: 4px;
  /* border: 1px solid #9dafc5; */
  padding: 3px;
}

.noSelected{  
  color: #345C8D;
  padding: 4px;
  border-bottom: 1px solid #345c8d3f;
  padding: 3px;
}

.dataSheet:hover {  
  color: #345C8D;
  background: rgba(167, 188, 255, 0.4);
} 


  .contentProduct {
    display: flex;
  }

  .product-info , .product-image {
    /* margin-top: 35px; */
    width: 50%;
}
  @media only screen and (max-width: 915px) {
    .contentProduct {
        display: block;
        justify-content: center;
    }

    .product-info , .product-image {
        margin-top: 35px;
        width: 100%;
    }

    .title, .subtitle {
        font-size: 30px !important;
    }
  }


  .cardColor {
    width: 22%;
}

.cardContainer{
    width: 68%;
    margin-left: 100px;
    padding-top: 30px;
    padding-left: 15px;
    justify-content: right;
    align-items: end;
    text-align: end;
}


.imageColor {
  width: 46px !important;
}

.thumbnailContainer {
  padding-top: 15px;
}

.thumbnail {
  width: 130px;
  margin: 15px;
  height: auto ; 
  margin: 5px;
  cursor: pointer; 
  border: solid 1px #adb5d1; 
   border-radius: 10%;
}

.thumbnailImage {
   width: 130px;
   margin: 15px;
   height: auto ; 
   margin: 5px;
   cursor: pointer; 
   border: solid 2px #28487A; 
    border-radius: 10%;
}

.table_container{
  overflow-x: auto;
}

.swiper-arrow-subfamily-root {
 
}

.swiper-arrow-subfamily {
  position: relative;
  display: block;
  justify-content: center;
  text-align: center;
  bottom: 5%;
  left: 100%;
  transform: translate(-50%, -50%);
  margin-top: 50px;
  margin-bottom: 40px; 
}

.swiper-arrow-subfamily span {
  display: block;
  width: 1.1rem;
  height: 1.1rem;
  border-bottom: 0.188rem solid #000000;
  border-right: 0.188rem solid #000000;
  transform: rotate(45deg);
  margin: -0.625rem;
  animation: animate-arrow 2s infinite;
}

.swiper-arrow-subfamily span:nth-child(2) {
  animation-delay: -0.1s;
}
.swiper-arrow-subfamily span:nth-child(3) {
  animation-delay: -0.2s;
}/*# sourceMappingURL=Slider.css.map */

/* .downloadDataSheets { 
    margin: 30px 20px 2px 0px;
    border: 3px solid green;
    color: "green";
    background-color: "#ffffff";
    border-radius: 0;
} */

/* .downloadDataSheet:hover {
  background-color: "green"; 
  color: 'green';
} */


@media only screen and (max-width: 954px){
   .container1 {
      display: block !important;
   }

   .container1 .sibiling1 {
      width: 100%;
   }
   
   .container1 .sibiling2 {
      width: 100%;
   }
}


/* ######### BUTTONS CHANGE PAGE ########### */
.buttons {  
  position: fixed;
  top: 60%;
  padding: 0 6px;
  width: 100%; 
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  pointer-events: none;
  /* text-align: center; */
}


.buttons button {
  background: none;
  border: none;
  margin: 10px;
  width: 50px;
  height: 60px;
  cursor: pointer;
  border-radius: 50%;
  /* border: 1px solid #a6aeb9; */
}

.buttons button:hover {
  /* background: #ddd9d9; */
  border: none;    
}

.buttons .button-wrapper {
  pointer-events: auto;
}

.buttons .button-wrapper .ArrowLeft img {
  width: 50px;
}

.buttons .button-wrapper .ArrowRight img {
  width: 50px;
  transform: rotate(180deg);
}

