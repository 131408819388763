@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.event {
  min-height: 700px;
  margin: 6.5rem 0 0 0;
  padding: 0 0 0 0; 
}

.event .row {
  margin-left: 0vw !important;
}

.event, .event-wrapper {
  min-height: 700px;
  margin-left: 0vw !important;
  margin-right: 0vw !important;
  justify-content: left;
  align-items: left;
  text-align: left;
}

.teste01{
  margin-top: 2rem;
}

.teste01 .row{
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}


@media screen and (max-width: 1300px) {
  .grid-container02 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card02 { 
  width: 21.1rem;
  height: 22rem;
  max-width: none;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  margin: 0 91px;
  padding: 0;  
}



.card-image02 {
  width: 100%;
  height: 18rem;
  object-fit: contain;
}

.card-body02 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 0px 16px 0px;
  border-top: solid black 1px;
  margin-top: 22px;
}

.card-title02 {
  text-align: left;
  align-items: start;
  font-size: 19px;
  font-weight: bold;
  margin-top: 8px;
  margin-left: 0 !important;
  padding-left: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-family: 'Roboto';
  font-weight: 400;
}

