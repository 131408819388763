   .responsiveTable {
    width: 100%;
    border-collapse: collapse;
}

.responsiveTable th, .responsiveTable td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 15px !important;
}

@media only screen and (max-width: 600px) {
    .responsiveTable th, .responsiveTable td {
        padding: 4px;
    }
}


.imageColorHeader {
    width: 100px;
}

.imageColor {
    width: 40px;
}
.containerColor{
    width: 100%;
}

.cardColor{
    width: 11%;
}
.hr {
    margin-top: 1px;
    border: 0;
    /* border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor; */  
    border-top: 1px solid rgba(0, 0, 0, 0.54);
}

.separatorOne {
    margin:0;
    padding: 0;
}



.cardContainer{
    width: 68%;
    margin-left: 100px;
    padding-left: 15px;
    justify-content: right;
    align-items: end;
    text-align: end;
}

@media only screen and (max-width: 954px){
    .cardContainer{
        width: 100%;       
        justify-content: center;
       margin: 0;
       padding: 0;
    }
    
 
   
 }
 