.box {
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
}

.productName {
    margin: 15px 0;
}

.price {
    font-weight: bold;
    margin-bottom: 15px;
}

.card {
    height: 100%;
    display: flex;
    justify-content: center;

}

.cardMedia {
    padding-top: 56%;
    width: 92%;
}