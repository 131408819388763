@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.event , .event-wrapper {
  min-height: 700px;  
  margin-left: 10vw;
  margin-right: 5vw;
  justify-content: center;
  align-items: center;
  text-align: center;  
}

.event-wrapper .row{
  margin-top: 0vw;
  margin-left: 0vw;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  width: 70vw;
}

.teste001{
  margin-top: 0vw;
}






